import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MetaData from "../component/layouts/MataData/MataData";
import TermsImage from "../Image/about/tc.jpg";
import { Link } from "react-router-dom";
import './Terms.css'
const useStyles = makeStyles((theme) => ({
  about_us: {
    paddingTop: "8rem",
    paddingBottom: "4rem",
    backgroundColor: "white !important",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Asul",
    fontStyle: "normal"
  },

  container_12: {
    padding: "2rem",
    textAlign: "center",

    backgroundColor: "white !important",
    maxWidth: "100%",
  },
  image_about: {
    width: "auto",
    maxHeight: "60vh",
    marginTop: "3rem",
    marginBottom: "2rem",
  },
  title_about: {
    color: "#414141",
    padding: "2rem 1rem 2rem",
    fontWeight: "500 !important",
  },
  heading12_about: {
    padding: "2rem 1rem 2rem",
    fontWeight: "400 !important",
    color: "#414141",
    textAlign: "center",
  },
  introText_about: {
    maxWidth: "800px",

    lineHeight: "1.5",
    margin: "1.5rem 0",
    color: "#292929",
    fontSize: "1.2rem",
    fontWeight: "400 !important",
    textAlign: "justify",
    padding: "0.8rem 1rem",
  },
  infoText_about: {
    lineHeight: "1.5",
    color: "#292929",
    fontSize: "1.1rem",
    fontWeight: "400 !important",
    textAlign: "justify",
    padding: "0.8rem 1rem",
  },
  buttonContainer_about: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
    width: "100%",
    marginTop: "1rem",
  },
  button1_about: {
    backgroundColor: "#000000 !important",
    color: "white !important",
    width: "fit-content !important",
    padding: "0.8rem 2rem   !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#00aaa4 !important",
      color: "white !important",
    },
  },
  button2_about: {
    backgroundColor: "#292929 !important",
    color: "white   !important",
    width: "fit-content     !important",
    padding: "0.8rem 2rem   !important",
    marginLeft: "1.3rem !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#00aaa4 !important",
      color: "white !important",
    }, 
  },
}));

const About_UsPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.about_us}>
        <MetaData title={"About Us"} />
        <Container className={classes.container_12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <img
                src={TermsImage}
                alt="The Sneaker Cave"
                className={classes.image_about}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h2"
                component="h1"
                className={classes.title_about}
              >
                About Us
              </Typography>
              <Typography variant="body1" className={classes.introText_about}>
                TheSneakerCave is an online store for sneakers that offers a wide range of the most popular brands. In our catalog, you will find products from leading brands such as Nike, Air Jordan, New Balance, and Yeezy. Whether you are an avid collector or looking for the perfect pair for everyday wear, TheSneakerCave is the place where you will find everything you need. Our mission is to provide you with the best models on the market, combining quality, style, and comfort. Visit us and enjoy exceptional service and fast delivery.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.container_12}>
          <Typography
            variant="h3"
            component="h1"
            className={classes.heading12_about}
          >
            Who are we? 
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center" }} className={classes.infoText_about}>
            TheSneakerCave was founded by a team of sneaker enthusiasts who share a common passion for unique design and high-quality sports footwear. We believe that sneakers are not just shoes, but an expression of personal style and lifestyle.
            Our team carefully selects each model to offer you the latest and most sought-after sneakers on the market. We strive to stay up-to-date with the latest trends and offer something for every taste – from classic and timeless models to the most modern and extravagant designs.
            At TheSneakerCave, we pride ourselves on our commitment to customers. We are here to help you with professional advice and personalized recommendations to find the perfect pair of sneakers. Our goal is to make every shopping experience pleasant and easy.
          </Typography>
        </Container>
        <Container className={classes.container_12}>
          <div className={classes.buttonContainer_about}>
            <Link
              to="/shop"
              style={{ textDecoration: "none", color: "none" }}
            >
              <Button variant="contained" className={classes.button1_about}>
                Find out!
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default About_UsPage;