import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Input,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { FaTrashCan } from "react-icons/fa6"; 
import {
  dispalyMoney,
  generateDiscountedPrice,
} from "../DisplayMoney/DisplayMoney";
import { convertShoeSize } from "../../constants/homeConstants";

const useStyles = makeStyles((theme) => ({
  root11: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "1rem 1rem",
    minWidth: "100%",
    borderRadius: "12px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    margin: "1rem 0", // Adjust margin for better spacing
    height: "auto",
    backgroundColor: "#212121",
    color: "#fff",

    [theme.breakpoints.down(899)]: {
      // padding: "3rem",
      flexDirection: "column"
      // margin: "1rem 3rem",
    },
    [theme.breakpoints.down(699)]: {
      // padding: "2rem",
      // margin: "1rem",
      width: "100%",
    },

    [theme.breakpoints.down(499)]: {
      // padding: "2rem",
      // margin: "1rem",
      width: "100%",
      boxShadow: "none",
    },
  },
  media: {
    minHeight: "40vh",
    width: "100%",
    marginLeft: "5%",
    objectFit: "cover", // Ensures the image maintains its aspect ratio
    borderRadius: "12px",

    [theme.breakpoints.down(899)]: {
      margin: 0,
      borderRadius: "0"
    },
    [theme.breakpoints.down(699)]: {
      width: "100%",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down(599)]: {
      width: "100%",
      height: "20vh",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down(499)]: {
      width: "100%",
      paddingRight: "1rem",
    },
  },
  content: {
    display: "flex", 
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%", // Ensure content takes full width
    alignItems: "center", // Center content
    marginRight: "20px",

    [theme.breakpoints.down(699)]: {
      padding: "0",
    },
    [theme.breakpoints.down(599)]: {
      padding: "0"
    },
  },
  contentTop: {
    marginTop: "10px",
    width: "60%"
  },
  cartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%", // Ensure header takes full width
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
    marginLeft: "1rem",
    [theme.breakpoints.down(599)]: {
      fontSize: "14px",
      marginLeft: "0",
    },
    "& .MuiTypography-subtitle1 ": {
      [theme.breakpoints.down(599)]: {
        fontSize: "14px",
      },
    },
  },
  cartDeleteIcon: {
    color: "#f9f9f9",
    marginTop: "-.5rem",
    [theme.breakpoints.down(599)]: {
      marginRight: "-2.5rem",
    },
    "&:hover": {
      color: "#f44336",
    },
    [theme.breakpoints.down(499)]: {
      marginRight: "-2rem",
    },
  },
  priceItem: {
    display: "flex",
    
    alignItems: "baseline",
    gap: "1rem",
    marginLeft: "1.2rem",
    [theme.breakpoints.down(599)]: {
      justifyContent: "justify-between",
      marginLeft: "0rem",
      marginRight: "-1rem",
    },
  },
  cartSubHeadings: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#e0e0e0",
    [theme.breakpoints.down(599)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(499)]: {
      fontSize: "12px",
    },
  },
  itemPrice: {
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down(599)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(499)]: {
      fontSize: "13px",
    },
  },
  itemOldPrice: {
    marginLeft: "-8px",
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down(499)]: {
      fontSize: "12px",
    },
  },
  contentBottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
    width: "100%", // Ensure content bottom takes full width
    flexDirection: "column",
    [theme.breakpoints.down(599)]: {
      marginLeft: "0rem",
      marginRight: "-1rem",
    },
    [theme.breakpoints.down(550)]: {
      position: "relative",
      marginLeft: "0rem",
    },
  },
  additem_decrease: {
    color: "#f9f9f9",
    backgroundColor: "#212121",
    "&:hover": {
      color: "#f44336",
    },
  },
  additem_increase: {
    color: "#f9f9f9",
    backgroundColor: "#212121",
    "&:hover": {
      color: "#00aaa4",
    },
  },
  itemQuantity: {
    color: "#f9f9f9",
    fontWeight: 500,
  }
}));
 
function CartItem({
  deleteCartItems,
  item,
  decreaseQuantity,
  increaseQuantity,
  length,
}) {
  const classes = useStyles();

  let finalPrice = generateDiscountedPrice(item.price);
  let discountedPrice = item.price - finalPrice;
  discountedPrice = dispalyMoney(discountedPrice); 
  let total = finalPrice * item.quantity;
  total = dispalyMoney(total);
  finalPrice = dispalyMoney(finalPrice);

  return (
    <Card className={classes.root11}>
      <CardMedia
        className={classes.media}
        image={item.image}
        title={item.name}
      />
      <CardContent className={classes.content}>
        <div className={classes.contentTop}>
          <div className={classes.cartHeader}>
            <Typography variant="subtitle1" className={classes.title}>
              {item.name}
            </Typography>

            <IconButton
              aria-label="delete"
              className={classes.cartDeleteIcon}
              onClick={() => deleteCartItems(item.productId, item.size, item.name)}
            >
              <FaTrashCan />
            </IconButton>
          </div>

          <div className={classes.priceItem}>
            <Typography className={classes.cartSubHeadings} variant="body2">
              Price:
            </Typography>
            <Typography variant="subtitle1" className={classes.itemPrice}>
              {dispalyMoney(item.price)}
            </Typography>
          </div>
        </div>
        <div className={classes.contentBottom}>
          <div style={{ display: "flex", flexDirection: "column"}}>
            <h5>Size:{" " + convertShoeSize(item.size)}</h5>
            <div style={{ display: "flex", flexDirection: "row"}}>
              <h5>Quantity: </h5>
              <div className="additem">
                <IconButton
                  onClick={() => decreaseQuantity(item.productId, item.size)}
                  className={classes.additem_decrease}
                >
                  <RemoveIcon />
                </IconButton>
                <Input
                  readOnly
                  type="number"
                  value={item.quantity}
                  className={classes.itemQuantity}
                />
                <IconButton
                  onClick={() => increaseQuantity(item.productId, item.size)}
                  className={classes.additem_increase}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            
          </div>
          <div className={classes.priceItem}>
            <Typography variant="body2" className={classes.cartSubHeadings}>
              Total:
            </Typography>
            <Typography variant="subtitle1" className={classes.price}>
              {dispalyMoney(item.price * item.quantity)}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default CartItem;
