/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './Product.css';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import useActive from '../hook/useActive';
import { clearErrors, getProductDetails } from '../../actions/productAction';
import { PRODUCT_DETAILS_RESET } from '../../constants/productsConstatns';
import { calculateDiscount, dispalyMoney, generateDiscountedPrice } from '../DisplayMoney/DisplayMoney';
import { addItemToCart } from '../../actions/cartAction';
import { Button, MenuItem, Select } from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FaTruckFast } from "react-icons/fa6";
import { LuPackageSearch } from "react-icons/lu";
import { GiWallet } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import { TbTruckReturn } from "react-icons/tb";
import ImageSlider from './ImageSlider';
import { convertShoeSize, euSizeToCentimeters } from '../../constants/homeConstants';
import Cards from "../../Image/Footer/cards.png";
import Revolut from "../../Image/Footer/Revolut.png";
import Stripe from "../../Image/Footer/Stripe.png";
import PayPal from "../../Image/Footer/paypal-logo.png";
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core';
import CashIcon from "../../Image/cart/cash-logo.png"

const useStyles = makeStyles((theme) => ({
  sizeButton: {
    color: '#fff !important', // Use !important to override default styles
    backgroundColor: '#121212',
  },
}));

const Product = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const alert = useAlert();
  const classes = useStyles()
  const [selectedSize, setSelectedSize] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [previewImg, setPreviewImg] = useState(null);
  const { handleActive, activeClass } = useActive(0);
  const [showSlider, setShowSlider] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('');
  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed
  const { product, loading, error, success } = useSelector(
    (state) => state.productDetails
  );
  const { cloaked, products } = useSelector((state) => state.products);
  const { cartItems } = useSelector(
    (state) => state.cart
  );

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);
    handleSizeChange({ target: { value } });
  };

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success && product) {
      setPreviewImg(product.images?.[0].url);
      setSelectedSize(Object.keys(product.Stock || [])[0]);
      handleActive(0);
      dispatch({ type: PRODUCT_DETAILS_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
    [dispatch, error, alert, success, match.params.id]);

  useEffect(() => {
    setPreviewImg(product.images?.[0].url);
  }, [product.images])

  // handling Add-to-cart
  const handleAddItem = () => {
    window.fbq('track', 'AddToCart', {
      content_ids: [product._id],
      content_type: 'product'
    });
    dispatch(addItemToCart(match.params.id, selectedSize, true));
    alert.success("The Product has been added to the basket.");
  };

  // handling Preview image
  const handlePreviewImg = (images, i) => {
    setPreviewImg(images[i].url);
    handleActive(i);
  };

  function increaseQuantityHandler() {
    if (product?.Stock[selectedSize] <= quantity) {
      return;
    }

    setQuantity((prv) => prv + 1);
  }

  function deceraseQuantityHandler() {
    if (quantity <= 1) {
      return;
    }
    setQuantity((prv) => prv - 1);
  }

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const getNonZeroStockCount = (stock) => {
    return Object.values(stock).reduce((count, qty) => count + (qty > 0 ? 1 : 0), 0);
  };

  const toggleModal = () => {
    setShowSlider((prev) => !prev);
  }

  return (
    <div className="product-container">
      <div className="image-gallery">
        {showSlider && <ImageSlider onClose={toggleModal} images={product?.images} />}
        <div onClick={toggleModal} className="main-image">
          <img src={previewImg} alt="Main" />
        </div>
        <div className="thumbnails">
          {product?.images?.map((image, index) => (
            <img
              src={image.url}
              alt={`Thumbnail ${index + 1}`}
              key={index}
              onClick={() => setPreviewImg(image.url)}
            />
          ))}
        </div>
      </div>
      <div className="product-details">
        <h1>{product?.name}</h1>
        <p style={{ color: '#888', fontWeight: 'normal' }}>{product?.category}</p>
        {/* <p>{product?.info && product.info}</p> */}
        <h3 className="price">{dispalyMoney(product?.price)}</h3>
        <div className="badge">
          {getNonZeroStockCount(product?.Stock || {}) > 0 ? (
            <span className="instock">
              <DoneIcon fontSize="50" /> In Stock
            </span>
          ) : (
            <span className="outofstock">
              <CloseIcon fontSize="100" />
              Out of Stock
            </span>
          )}
        </div>
        <div className="product-description">
          <p>
            
            <br />
            🔥MadeToBeWorn, not resold
            <br />
            🔥Affordable drip, using real materials
            <br />
          
            <br />
            {/* {product?.description} */}
          </p>
          <div className="size-chart">
            <div>
              <h3>Choose a size</h3>
              {isMobileOrTablet ? (
                <Select
                  value={dropdownValue}
                  onChange={handleDropdownChange}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Size
                  </MenuItem>
                  {Object.keys(product?.Stock || {}).map((size, index) => (
                    <MenuItem value={size} key={index}>
                      {convertShoeSize(size)} ( {euSizeToCentimeters(size)} )
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <div className="sizes">
                  {Object.keys(product?.Stock || {}).map((size, index) => (
                    <Button
                      className={`${selectedSize === size ? "size-button-selected" : "size-button"}`}
                      value={size}
                      onClick={handleSizeChange}
                      key={index}
                    >
                      {convertShoeSize(size)}
                      <br />
                      ( {euSizeToCentimeters(size)} )
                    </Button>
                  ))}
                </div>
              )}
            </div>
            <div className="button-container">
              <Button
                variant="contained"
                className="prod_details_addtocart_btn"
                onClick={handleAddItem}
                disabled={product.Stock && product?.Stock[selectedSize] && product.Stock[selectedSize] > 0 ? false : true}
              >
                {product.Stock && product?.Stock[selectedSize] && product.Stock[selectedSize] > 0 ? "Add To Basket" : "Out of Stock"}
              </Button>
            </div>
          </div>
        </div>
        <div className='payment-options'>
          {!cloaked && (
            <a href="https://stripe.com/en-bg/legal/consumer">
              <img style={{ width: 90, height: 'auto' }} src={Stripe} alt="play Store svg" />
            </a>
          )}
          {!cloaked && (
            <a href="https://www.paypal.com/us/legalhub/useragreement-full">
              <img style={{ width: 90, height: 'auto' }} src={PayPal} alt="play Store svg" />
            </a>
          )}
          <a href='https://usa.visa.com/support/consumer/visa-rules.html'>
            <img style={{ width: 180, height: 'auto' }} src={Cards} alt="play Store svg" />
          </a>
          <a href="#">
            <img style={{ width: 40, height: 'auto', marginLeft: 5, }} src={CashIcon} alt="play Store svg" />
          </a>
        </div>
   
                
      
        <div className="shipping-info">
          <p><FaTruckFast size={20} />{" "}Free Worldwide Shipment (1-2 Business Days) </p>
          <p><TbTruckReturn size={20} />{" "}Pay upon delivery | Pay via card</p>
          <p><GiWallet size={20} />{" "}14-Days Money Back Guarantee</p>
          <p><RiCustomerService2Line size={20} />{" "}Top Rated Customer Support</p>
          <Button style={{ marginTop: '15px' }} className='see_more_btn' href="/shop">See other Sneakers</Button>
        </div>
      </div>
    </div>
  );
};

export default Product;
