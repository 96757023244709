import React, { useState } from "react";
import { PaymentElement, useStripe, useElements, AddressElement } from "@stripe/react-stripe-js";
import { Button, makeStyles, Radio, RadioGroup, FormControlLabel, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createOrder } from "../../actions/orderAction";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert"; // Assuming you are using react-alert
import PayPalIntegration from "../../constants/PayPal";
import PayPalIcon from "../../Image/cart/paypal-logo.png"; // Add PayPal icon here
import StripeIcon from "../../Image/cart/stripe-logo.png"; // Add Stripe icon here
import CashIcon from "../../Image/cart/cash-logo.png";
import { PiShieldWarningFill } from "react-icons/pi";
import { FaRegAddressCard } from "react-icons/fa6";

import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";


const useStyles = makeStyles((theme) => ({
    placeOrderBtn: {
        backgroundColor: "#000",
        color: "#fff",
        fontWeight: "500",
        fontSize: "1rem",
        padding: "0.8rem 1rem",
        borderRadius: "0.5rem",
        width: "100%",
        marginTop: "1rem",
        "&:hover": {
            backgroundColor: "#00e3db",
            transition: "0.3s ease-in-out",
        },
    },
    securePayemnt: {
      display: "flex",
      alignItems: "center",
      fontWeight: "300",
      backgroundColor: "#f5f5f5 !important",
      width: "90%",
      padding: "1rem",
      gap: "0.8rem",
      marginBottom: "1rem",
      marginTop: "2rem",
      borderRadius: "5px",
      "& svg": {
        fontSize: "2rem",
      },
    },
    
    
    paymentOptions: {
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        marginTop: "2rem",
    },
    radioOption: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    icon: {
        width: "24px",
        height: "24px",
        marginRight: "0.5rem",
    },
}));

const CheckoutForm = ({ order, totalFinalPrice, cartItems }) => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();

    const [paymentMethod, setPaymentMethod] = useState("stripe");
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (paymentMethod === "paypal") {
            alert.success("Payment successful!");

            window.fbq("track", "Purchase", {
                content_ids: cartItems.map((item) => item.productId),
                currency: "EUR",
                value: totalFinalPrice,
                content_type: "product",
            });

            dispatch(createOrder(order));
            history.push("/success");

            return;
        }
        if (paymentMethod === "cash") {
            alert.success("Order placed!");

            window.fbq("track", "Purchase", {
                content_ids: cartItems.map((item) => item.productId),
                currency: "EUR",
                value: totalFinalPrice,
                content_type: "product",
            });

            dispatch(createOrder(order));
            history.push("/success");

            return;
        }
        if (!stripe || !elements) return;

        

        setIsLoading(true);
        const addressElement = elements.getElement("address");
        const { complete } = await addressElement.getValue();

        if (complete) {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {},
                redirect: "if_required",
            });

            if (error) {
                setMessage(error.message || "An unexpected error occurred.");
                setIsLoading(false);
                return;
            }

            if (paymentIntent?.status === "succeeded") {
                order.paymentInfo = {
                    id: paymentIntent.id,
                    status: paymentIntent.status,
                };
                alert.success("Payment successful!");

                window.fbq("track", "Purchase", {
                    content_ids: cartItems.map((item) => item.productId),
                    currency: "EUR",
                    value: totalFinalPrice,
                    content_type: "product",
                });

                dispatch(createOrder(order));
                history.push("/success");
            }
            setIsLoading(false);
        } else {
            setMessage("Please fill all required fields");
            setIsLoading(false);
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <RadioGroup
                row
                className={classes.paymentOptions}
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
            >
                <FormControlLabel
                    value="stripe"
                    control={<Radio />}
                    label={
                        <div className={classes.radioOption}>
                            <img src={StripeIcon} alt="Stripe" className={classes.icon} />
                            Pay with Stripe
                        </div>
                    }
                />
                <FormControlLabel
                    value="paypal"
                    control={<Radio style={{ color: "#00e3db" }} />}
                    label={
                        <div className={classes.radioOption}>
                            <img src={PayPalIcon} alt="PayPal" className={classes.icon} />
                            Pay with PayPal
                        </div>
                    }
                />
                <FormControlLabel
                    value="cash"
                    control={<Radio style={{ color: "#52a447" }} />}
                    label={
                        <div className={classes.radioOption}>
                            <img src={CashIcon} alt="Cash" className={classes.icon} />
                            Pay upon delivery
                        </div>
                    }
                />
            </RadioGroup>

            {paymentMethod === "stripe" && (
                <>
                    <AddressElement options={{ mode: "shipping" }} />
                    <PaymentElement id="payment-element" />
                </>
            )}
            {paymentMethod === "paypal" && <PayPalIntegration onSuccess={handleSubmit} amount={totalFinalPrice} />}
            {paymentMethod === "stripe" && (
                <Button
                    variant="contained"
                    className={classes.placeOrderBtn}
                    fullWidth
                    type="submit"
                    disabled={isLoading || !stripe || !elements || paymentMethod === "paypal"}
                    style={{ marginTop: "3rem" }}
                >
                    {isLoading ? "Processing..." : "Pay With Stripe"}
                </Button>
            )}
            {paymentMethod === "cash" && (
                <>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.securePayemnt}
                >
                  <PiShieldWarningFill/>
                  Please note that payment upon delivery option is being handled by post services and you need to pay via card ot cash when you collect your product/s
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.securePayemnt}
                >
                  <FaRegAddressCard />
                  By clicking 'Place Order' you agree that you have correctly chosen the addres you want your product to be shipped to (office | personal address)
                </Typography>
                <Button
                    variant="contained"
                    className={classes.placeOrderBtn}
                    fullWidth
                    type="submit"
                    disabled={isLoading || !stripe || !elements || paymentMethod === "paypal"}
                    style={{ marginTop: "3rem" }}
                >
                    Place Order
                </Button>

                </>
               
            )}
            
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
};

export default CheckoutForm;
