import React, { useState } from 'react';
import './Dropdown.css';

const DropdownMenu = ({ categories, selectedCategory, handleCategoryChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <button className="dropdown-button" onClick={toggleDropdown}>
        Select Categories
      </button>
      <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
        <ul className="categoryBox">
          {categories.map((category, index) => (
            <li className="category-link" key={index}>
              <label htmlFor={`category-${index}`} className="category-label">
                <input
                  type="checkbox"
                  id={`category-${index}`}
                  className="category-checkbox"
                  value={category}
                  checked={category === selectedCategory}
                  onChange={() => handleCategoryChange(category)}
                />
                {category}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownMenu;
