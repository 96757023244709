import React, { useEffect } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import MetaData from "../layouts/MataData/MataData";
import CheckoutSteps from "./CheckoutSteps";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

import {
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  shippingRoot: {
    width: "60%",
    margin: "auto",
  },
  heading: {
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
    backgroundColor: "#00aaa4",
    color: "#FFFFFF",
    height: "4rem",
    "&:hover": {
      backgroundColor: "#141414",
      color: "#FFFFFF",
    },
  },
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#101025000",
      },
      "&:hover fieldset": {
        borderColor: "#101025000", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "#101025000", 
      },
    },
    "& .MuiInputBase-input": {
      color: "#101025000", 
    },
    "& .MuiInputLabel-root": {
      color: "#101025000", 
    },
  },


}));

const Shipping = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shippingInfo, cartItems, ...cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.userData);

  const classes = useStyles();
  const [address, setAddress] = React.useState(shippingInfo.address);
  const [firstName, setFirstName] = React.useState(shippingInfo.firstName);
  const [lastName, setLastName] = React.useState(shippingInfo.lastName);
  const [city, setCity] = React.useState(shippingInfo.city);
  const [pinCode, setPinCode] = React.useState(shippingInfo.pinCode);
  const [state, setState] = React.useState(shippingInfo.state);
  const [country, setCountry] = React.useState(shippingInfo.country);
  const [phoneNo, setPhone] = React.useState(shippingInfo.phoneNo || "");
  const [email, setEmail] = React.useState(user.email ? user.email : shippingInfo.email);
  const [saveAddress, setSaveAddress] = React.useState(false);
  const [sameBillingDelivery, setSameBillingDelivery] = React.useState(false);
const [isValidEmail, setIsValidEmail] = React.useState(true);
const [isPhoneNoValid, setIsPhoneNoValid] = React.useState(true);
const [readonly, setReadonly] = React.useState(user.email ? true : false);

  useEffect(() => {
    // window.fbq('trackCustom', 'InitializedCheckout', {
    //   items_quantity: cart?.cartItems?.length,
    //   items_overall_price: cart?.cartItems?.reduce((acc, item) => acc + item.price * item.quantity, 0),
    //   items_models: cart?.cartItems?.map(item => item.name).join(', '),
    //   items_sizes: cart?.cartItems?.map(item => item.size).toString()
    // });

  }, [])

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePincodeChange = (event) => {
    const input = event.target.value;
  
    // Check if the input is a valid number (using a regex that matches digits only)
    if (/^\d*$/.test(input)) {
      setPinCode(input.length ? Number(input) : '');
    }
  };
  
  const handleStateChange = (event) => {

    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const newPhoneNo = event.target.value;
    setPhone(newPhoneNo);
    setIsPhoneNoValid(newPhoneNo !== "" && newPhoneNo?.length >= 9 && newPhoneNo?.length <= 11);
  }; 

 const handleEmailChange = (event) => {
  if(!readonly){
    const newEmail = event.target.value;
    
    setEmail(newEmail);
    setIsValidEmail(
      newEmail.trim(" ").length === 0 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
    );
  }
   
 };

  const handleSaveAddressChange = (event) => {
    setSaveAddress(event.target.checked);
  };

  const handleSameBillingDeliveryChange = (event) => {
    setSameBillingDelivery(event.target.checked);
  };

  useEffect(() => {
    if (user.email) {
      setEmail(user.email);
      setReadonly(true);
    }else{
      setReadonly(false);
    }
  }, [user])

 const handleSubmit = (event) => {
   event.preventDefault();
   if (
    (!email || email.trim().length === 0) ||
    (!firstName || firstName.trim().length === 0) ||
    (!lastName || lastName.trim().length === 0) ||
    (!address || address.trim().length === 0) ||
    (!city || city.trim().length === 0) ||
    (!country || country.trim().length === 0) ||
    (!phoneNo || phoneNo.trim().length === 0)
  ) {
    alert.error("Please fill up all fields.");
    return;
  }
  
  //  if (phoneNo && (phoneNo?.length <= 9 || phoneNo?.length >= 11)) {
  //   alert.error("Please type in a valid phone number.");
  //   return;
  // }
  
  //  if(String(pinCode).length !== 4){
  //   alert.error("Please type in a valid postal code.");
  //   return
  // }

  window.fbq('track', 'InitiateCheckout', {
    content_ids: cartItems.map(item => item._id),  // List of product IDs in the cart
    content_type: 'product',                      // Content type
    value: cartItems.reduce((acc, currItem) => {
      return acc + currItem.quantity * currItem.price;
    }, 0),                            // Total price for all products in the cart
    currency: 'EUR',                              // Currency for the transaction
    num_items: cartItems.length,                  // Total number of items in the cart
  });
  

   dispatch(
     saveShippingInfo({
       address,
       city,
       state,
       country,
       pinCode,
       phoneNo,
       email,
       firstName,
       lastName,
     })
   );
   history.push("/process/payment");
 };


  



  return (
  <>
    <div className="shippingPage">
      <MetaData title={"Shipping Info"} />
      <CheckoutSteps activeStep={1} />

      <div className="shippingPage__container">
        <div className="shippingPage__container__left">
          <div className={classes.shippingRoot}>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" className={classes.heading}>
                Shipping Address
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    value={firstName}
                    onChange={handleFirstNameChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    onChange={handleLastNameChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={address}
                    onChange={handleAddressChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="City"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={handleCityChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    value={pinCode}
                    onChange={handlePincodeChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="State"
                    variant="outlined"
                    fullWidth
                    value={state}
                    onChange={handleStateChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Country"
                    variant="outlined"
                    fullWidth
                    value={country}
                    onChange={handleCountryChange}
                    className={classes.outlinedInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={phoneNo}
                    onChange={handlePhoneChange}
                    className={classes.outlinedInput}
                    // error={!isPhoneNoValid && phoneNo !== ""}
                    // helperText={
                    //   !isPhoneNoValid &&
                    //   phoneNo &&
                    //   "Please enter a valid phone number."
                    // }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    aria-readonly={readonly}
                    onChange={handleEmailChange}
                    className={classes.outlinedInput}
                    error={!isValidEmail && email !== ""}
                    helperText={
                      !isValidEmail &&
                      email &&
                      "Please enter a valid email."
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={saveAddress}
                        style={{ color: "#101025000" }}
                        onChange={handleSaveAddressChange}
                      />
                    }
                    label="Save Address to Address Book"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameBillingDelivery}
                        style={{ color: "#101025000" }}
                        onChange={handleSameBillingDeliveryChange}
                      />
                    }
                    label="My billing and delivery information are the same."
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                  >
                    Continue to Payment
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Shipping;
