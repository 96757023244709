import React from "react";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";
import { useSelector } from "react-redux";

const DeliveryAndPayment = () => {
  const { cloaked } = useSelector((state) => state.products);

  return (
    <div className="privacy-policy-container">
      <MetaData title="Payment and Delivery" />
      <div className="container___">
        <h1>Payment and Delivery</h1>
        <p>
          We ship worldwide to nearly every country across the globe. We employ
          the services of major, trusted international carriers to ensure your
          package arrives at your destination fast and securely. We also offer
          free flat-rate shipping on all our products to most destinations.
        </p>
        
        <h2>Order Successful</h2>
        <p>
          After you have successfully placed your order, you will receive an email
          confirmation from us. If you have any requests for your order, kindly
          let us know as soon as possible before your item enters the processing
          stage.
        </p>
        
        <h2>Processing</h2>
        <p>
          <strong>Note:</strong> In orders with more than one item, the processing
          time will be based on the item with the longest processing time.
        </p>
        <p>
          We will carry out strict quality controls on your items and ensure they
          are properly packed before preparing them for shipment. We are happy to
          announce that now most orders can be shipped out within 24 hours. Kindly
          note a small quantity of orders may still need between 3-5 business days
          depending on stock availability.
        </p>
        <p>
          Please note that the processing time is not included in the shipping
          time. The total time it will take to receive your order is the processing
          time + the shipping time. If you have any issues with your order, you can
          submit a ticket to our Support Center for further assistance. Our
          dedicated CS team will contact you within 24 hours.
        </p>
        
        <h2>Shipping</h2>
        <p>
          We partner with major international shipping companies to offer different
          methods. Usually, we will ship by UPS or DHL.
        </p>
        <p>
          <strong>Note:</strong> Shipping times may be affected during public
          holidays as manufacturers and couriers will limit their operations during
          these times. Though this is unfortunately out of our control, we will try
          our best to improve on this issue.
        </p>

        <h2>Estimated Shipping Time</h2>
        <p>Delivery: <strong>1-2 days</strong></p>
        
        <h2>Payment Methods We Accept</h2>
        <p>We accept <strong>Visa, Mastercard, Discover, American Express</strong> through Stripe and PayPal.
          When you check out your order, you can select which method you would like to use. After providing the payment information, your card/account will automatically be charged by our payment provider. Your order will begin processing after all necessary verification procedures have been completed.
        </p>
        <p>
          If you choose the "Pay Upon Delivery" option, please make sure to provide the exact address of an office or home address where you would like your products delivered. Delivery for this option is typically completed within 1-2 days.
        </p>
        
        <h2>Delivered</h2>
        <p>
          <strong>Note:</strong> For tracking numbers, it may take a few hours before
          they become active on the tracking website. If the information is not
          available, please try again later. Please rest assured that the estimated
          shipping time is valid regardless of the tracking site updates.
        </p>
        
        <h2>Tax</h2>
        <p>
          Kindly note that all packages are shipped from different warehouses. Our
          items are shipped duty unpaid, and therefore import tax may occur
          depending on your country’s Customs and shipping method. You may check
          with your local Customs Office for more information.
        </p>
        
        <h2>Contact Us</h2>
        <p>
          If there is any issue during the shipping time or after receiving the item,
          please feel free to contact us. Our customer service representatives will
          offer you a satisfying solution.
        </p>
        
        <h2>Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <p style={{ fontWeight: "500" }}>TheSneakerCave</p>
        {/* {
          cloaked 
            ? <p style={{ fontWeight: "500" }}>Zhitnitsa Village, Postal Code 9278, Provadya Municipality, Varna Province.</p>
            : <p style={{ fontWeight: "500" }}>8 Argall Ave, London E10 7QE, United Kingdom</p>
        } */}
        <p style={{ fontWeight: "500" }}>
          Email:{" "}
          <span style={{ fontWeight: "400" }}>support@thesneakercave.com</span>
        </p>
        <p style={{ fontWeight: "500" }}>
          Instagram:{" "}
          <span style={{ fontWeight: "400" }}>_the_sneaker_cave_</span>
        </p>
        <p style={{ fontWeight: "500" }}>
          Facebook:{" "}
          <span style={{ fontWeight: "400" }}>TheSneakerCave</span>
        </p>
        {/* { cloaked 
        ? <p style={{ fontWeight: "500" }}>
            Phone Number:{" "}
            <span style={{ fontWeight: "400" }}>+359877046728</span>
          </p>
        : <p style={{ fontWeight: "500" }}>
            Phone Number:{" "}
            <span style={{ fontWeight: "400" }}>+447453347455</span>
          </p>
        } */}
      </div>
    </div>
  );
};

export default DeliveryAndPayment;