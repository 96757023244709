import CryptoJS from 'crypto-js';

// get Products
export const ALL_PRODUCT_REQUEST ="ALL_PRODUCT_REQUEST"
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Types
export const SHUFFLED_PRODUCTS_REQUEST = 'SHUFFLED_PRODUCTS_REQUEST';
export const SHUFFLED_PRODUCTS_SUCCESS = 'SHUFFLED_PRODUCTS_SUCCESS';
export const SHUFFLED_PRODUCTS_FAIL = 'SHUFFLED_PRODUCTS_FAIL';

export const LATEST_PRODUCTS_REQUEST = 'LATEST_PRODUCTS_REQUEST';
export const LATEST_PRODUCTS_SUCCESS = 'LATEST_PRODUCTS_SUCCESS';
export const LATEST_PRODUCTS_FAIL = 'LATEST_PRODUCTS_FAIL';

export const SET_CLOAKED = 'SET_CLOAKED';

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAIL = 'COUNTRIES_FAIL';

// admin product request
export const ADMIN_PRODUCT_REQUEST = "ADMIN_PRODUCT_REQUEST";
export const ADMIN_PRODUCT_SUCCESS = "ADMIN_PRODUCT_SUCCESS";
export const ADMIN_PRODUCT_FAIL = "ADMIN_PRODUCT_FAIL";

export const ADMIN_DUMMY_PRODUCT_REQUEST = "ADMIN_DUMMY_PRODUCT_REQUEST";
export const ADMIN_DUMMY_PRODUCT_SUCCESS = "ADMIN_DUMMY_PRODUCT_SUCCESS";
export const ADMIN_DUMMY_PRODUCT_FAIL = "ADMIN_DUMMY_PRODUCT_FAIL";

// create new Product --> admin

export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";

export const NEW_DUMMY_PRODUCT_REQUEST = "NEW_DUMMY_PRODUCT_REQUEST";
export const NEW_DUMMY_PRODUCT_SUCCESS = "NEW_DUMMY_PRODUCT_SUCCESS";
export const NEW_DUMMY_PRODUCT_RESET = "NEW_DUMMY_PRODUCT_RESET";
export const NEW_DUMMY_PRODUCT_FAIL = "NEW_DUMMY_PRODUCT_FAIL";

// Product details --> 
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

// Add a review
export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";



// Delete Product
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const DELETE_DUMMY_PRODUCT_REQUEST = "DELETE_DUMMY_PRODUCT_REQUEST";
export const DELETE_DUMMY_PRODUCT_SUCCESS = "DELETE_DUMMY_PRODUCT_SUCCESS";
export const DELETE_DUMMY_PRODUCT_RESET = "DELETE_DUMMY_PRODUCT_RESET";
export const DELETE_DUMMY_PRODUCT_FAIL = "DELETE_DUMMY_PRODUCT_FAIL";

//Udate Product =>
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

// Update Countries
export const UPDATE_COUNTRIES_REQUEST = "UPDATE_COUNTRIESREQUEST";
export const UPDATE_COUNTRIES_SUCCESS = "UPDATE_COUNTRIES_SUCCESS";
export const UPDATE_COUNTRIES_FAIL = "UPDATE_COUNTRIES_FAIL";
export const UPDATE_COUNTRIES_RESET = "UPDATE_COUNTRIES_RESET";

export const UPDATE_DUMMY_PRODUCT_REQUEST = "UPDATE_DUMMY_PRODUCT_REQUEST";
export const UPDATE_DUMMY_PRODUCT_SUCCESS = "UPDATE_DUMMY_PRODUCT_SUCCESS";
export const UPDATE_DUMMY_PRODUCT_RESET = "UPDATE_DUMMY_PRODUCT_RESET";
export const UPDATE_DUMMY_PRODUCT_FAIL = "UPDATE_DUMMY_PRODUCT_FAIL";


// get all review --> admin

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";



// delete review admin =>
export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

// Sizes
export const sizes = [32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48];

export const europeanCountries = [
  { code: "AL", name: "Albania" },
  { code: "AD", name: "Andorra" },
  { code: "AM", name: "Armenia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BG", name: "Bulgaria" },
  { code: "HR", name: "Croatia" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "EE", name: "Estonia" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GR", name: "Greece" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IE", name: "Ireland" },
  { code: "IT", name: "Italy" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "XK", name: "Kosovo" },
  { code: "LV", name: "Latvia" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MT", name: "Malta" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "ME", name: "Montenegro" },
  { code: "NL", name: "Netherlands" },
  { code: "MK", name: "North Macedonia" },
  { code: "NO", name: "Norway" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russia" },
  { code: "SM", name: "San Marino" },
  { code: "RS", name: "Serbia" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "ES", name: "Spain" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "TR", name: "Turkey" },
  { code: "UA", name: "Ukraine" },
  { code: "GB", name: "United Kingdom" },
  { code: "VA", name: "Vatican City" }
];

// Categories
export const categories = [
    "Yeezy",
    "NK",
    "NK Scorpion",
    // "NK Dunk Low",
    "NK AF1",
    "NK Air Max",
    "NK SB",
    "NK VaporMax",
    "AJ4",
    "AJ1",
    // "AJ",
    "NB",
    "Sneaker"
  ];

export const assignIds = (images, prefix = 'img') => {
  return images.map((image, index) => ({
    ...image,
    id: `${prefix}-${index}-${Date.now()}`
  }));
};

export const decryptCookieValue = (encryptedValue, secretKey) => {
  const decodedValue = decodeURIComponent(encryptedValue); // Decode the URL-encoded value
  const bytes = CryptoJS.AES.decrypt(decodedValue, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};