import { ADD_TO_CART, REMOVE_CART_ITEM, SAVE_SHIPPING_INFO, RESET_CART, TOGGLE_HOME_DELIVERY } from "../constants/cartConstant";


export function cartReducer(state = { cartItems: [], shippingInfo: {}, homeDelivery: true }, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      // find if product exist in cartItem already
      const isExist = state.cartItems.find(cartItem => {
        return cartItem.productId === item.productId && cartItem.size === item.size
      })
   
      // if exist alerady then replace same product 
      if (isExist) {
        return {
          ...state,
          cartItems: state.cartItems.map((cartItem) => {
            return item.productId === cartItem.productId && cartItem.size === item.size ? item : cartItem;
          }),
        };
      }
      // if not exist then add new item value into cartItem
      else {
        return {
          ...state,
          cartItems: [...state.cartItems, item]
        }
      }
    case TOGGLE_HOME_DELIVERY:
      return {
        ...state,
        homeDelivery: !state.homeDelivery
      }
    case REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(item => !(item.productId === action.payload.id && item.size === action.payload.size))
      }
      
    case SAVE_SHIPPING_INFO: return {
      ...state,
      shippingInfo:  action.payload,
    };

    case RESET_CART: 
      localStorage.removeItem('cartItem')
      return {
        ...state,
        cartItems: []
      }

    default: return state
  }


}

