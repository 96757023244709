import React, { useEffect, useState } from "react";
import "./Home.css";
import MataData from "../layouts/MataData/MataData";
import { clearErrors, getLatestProducts, getShuffledProducts } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import HeroSlider from "./HeroSilder";
import Banner from "./BannerStack/Banner";
import ProductGrid from "./BannerStack/ProductGrid";
import { dummySlides, getRandomRange, mainBanner, sliderData, slides, slides2 } from "../../constants/homeConstants";
import SliderComponent from "./BannerStack/SliderComponent";
import ProductSlider from "./BannerStack/ProductSlider";
import HomeCard from "./HomeCard";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ContactUsCard from "./ContactUsCard";

function Home() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [badConnection, setBadConnection] = useState(false)
  const { loading, error, latestProducts, shuffledProducts, cloaked } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getLatestProducts());
    dispatch(getShuffledProducts());
  }, [dispatch]);
   
  useEffect(() => {
    if (error) {
      setBadConnection(true)
      // alert.error(error);
      dispatch(clearErrors());
    }
  }, [error, alert, dispatch]);

  return (
    <>
      {loading || (!latestProducts && !shuffledProducts) ? ( 
        <div>
          <Loader badConnection={badConnection}  />
        </div>
        
      ) : (
        <> 
          <MataData title="The Sneaker Cave" />
          <div className="Home_Page">
            { !cloaked ?
              <HeroSlider navButtons={true} animation={"slide"} slides={slides} />
            : <HeroSlider navButtons={true} animation={"slide"} slides={dummySlides} />
            }
            <h1>New Arrivals</h1>
            <div className="newArrivals_Grid">
              {latestProducts?.length > 0 ? (
                latestProducts?.map((product) => (
                  <HomeCard product={product} />
                ))
              ) : (
                <p>No new arrivals available.</p>
              )}
            </div>
            { !cloaked ?
              <>
                <Banner props={mainBanner} />
                <ProductGrid />
              </>
            : <></>}
            <br />
            <h1>Check in the Hottest Sneakers.</h1>
            <ProductSlider>
              {shuffledProducts?.length > 0 ? (
                shuffledProducts?.map((product) => (
                  <HomeCard key={product._id} product={product} />
                ))
              ) : (
                <p>No products available.</p>
              )}
            </ProductSlider>
            <br />
            { !cloaked ?
              <>
                <HeroSlider navButtons={false} animation={"fade"} slides={slides2} />
                <br />
                <h1>Hottest Models.</h1>
                <ProductSlider>
                  {sliderData.map((item, index) => (
                    <div key={index} className="slider-item">
                      <Link to={`/shop/${item.url}`} rel="noopener noreferrer">
                        <img src={item.image} alt={item.name} />
                        <h3>{item.name}</h3>
                      </Link>
                    </div>
                  ))}
                </ProductSlider>
              </>
            : <></>}
            <div className="contact_us">
              <ContactUsCard/>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
