import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { myOrders, clearErrors } from "../../actions/orderAction";
import MetaData from "../layouts/MataData/MataData";
import CricketBallLoader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import OrderCard from "./OrderCard";
import './Myorder.css'

const MyOrder = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { orders, loading, error } = useSelector((state) => state.myOrder);
  const { user } = useSelector((state) => state.userData);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(myOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [dispatch, alert, error]);

  return (
    <>
      {loading ? (
        <CricketBallLoader />
      ) : (
        <div>
          <MetaData title="My Orders" />
          <div className='orderPageContainer'>
            <Typography variant="h6" className='orderPageTitle'>
              Your Orders
            </Typography>
            <Typography variant="body1" className='orderPageText'>
              {orders?.length} Orders From {currentYear}
            </Typography>
          </div>

          {orders.map((item) => (
            <div className='orderCard' key={item._id}>
              <OrderCard item={item} user={user} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MyOrder;
