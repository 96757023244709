import React from 'react'
import './ProductGrid.css';
import ProductCard from './ProductCard';
import Banner from './Banner';
import { gridImages } from '../../../constants/homeConstants';


export function ProductGrid() {
  return (
    <div className="product-grid">
      {gridImages.map((grid, id) => (
        <Banner props={grid} key={id}/>
        // <ProductCard key={product.id} title={product.title} image={product.image} link={product.link} />
      ))}
    </div>
  );
}

export default ProductGrid;
