import React from "react";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";

const TermsOfService = () => {
  return (
    <div className="privacy-policy-container">
      <MetaData title="Terms Of Service" />
      <div className="container___">
        <h1>Terms Of Service</h1>
        <p>
          This website is operated by TheSneakerCave. Throughout the site, the terms “we”, “us” and “our” refer to TheSneakerCave. TheSneakerCave offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.
        </p>
        <p>
          By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
        </p>
        <p>
          Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
        </p>
        <p>
          Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
        </p>

        <h2>SECTION 1 – ONLINE STORE TERMS</h2>
        <p>
          By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
        </p>
        <p>
          You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or any code of a destructive nature.
        </p>
        <p>
          A breach or violation of any of the Terms will result in an immediate termination of your Services.
        </p>

        <h2>SECTION 2 – GENERAL CONDITIONS</h2>
        <p>
          We reserve the right to refuse service to anyone for any reason at any time.
        </p>
        <p>
          You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
        </p>
        <p>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
        </p>

        <h2>SECTION 3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
        <p>
          We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
        </p>
        <p>
          This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
        </p>

        <h2>SECTION 4 – MODIFICATIONS TO THE SERVICE AND PRICES</h2>
        <p>
          Prices for our products are subject to change without notice.
        </p>
        <p>
          We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
        </p>
        <p>
          We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
        </p>

        <h2>SECTION 5 – PRODUCTS OR SERVICES (if applicable)</h2>
        <p>
          Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
        </p>
        <p>
          We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor’s display of any color will be accurate.
        </p>

        <h2>SECTION 6 – ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
        <p>
          We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
        </p>
        <p>
          You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
        </p>

        <h2>SECTION 7 – OPTIONAL TOOLS</h2>
        <p>
          We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
        </p>
        <p>
          You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
        </p>

        <h2>SECTION 8 – THIRD-PARTY LINKS</h2>
        <p>
          Certain content, products, and services available via our Service may include materials from third-parties.
        </p>
        <p>
          Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
        </p>

        <h2>SECTION 9 – USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
        <p>
          If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us.
        </p>

        <h2>SECTION 10 – PERSONAL INFORMATION</h2>
        <p>
          Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy, visit our site’s Privacy Policy section.
        </p>

        <h2>SECTION 11 – ERRORS, INACCURACIES AND OMISSIONS</h2>
        <p>
          Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times, and availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice.
        </p>

        <h2>SECTION 12 – PROHIBITED USES</h2>
        <p>
          In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content for any unlawful purpose.
        </p>

        <h2>SECTION 13 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
        <p>
          We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure, or error-free.
        </p>
        <p>
          In no case shall TheSneakerCave, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind.
        </p>

        <h2>SECTION 14 – INDENIFICATION</h2>
        <p>
          You agree to indemnify, defend and hold harmless TheSneakerCave and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service.
        </p>

        <h2>SECTION 15 – SEVERABILITY</h2>
        <p>
          In the event that any provision of these Terms of Service is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law.
        </p>

        <h2>SECTION 16 – GOVERNING LAW</h2>
        <p>
          These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of your jurisdiction.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
