import React from "react";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";
import PrivacyPolicyImage from "../Image/1.png";

const GDPR = () => {
  return (
    <div className="container__0">
      <MetaData title="GDPR Privacy Policy" />
      <div className="image-container">
        <img
          src={PrivacyPolicyImage}
          alt="Background"
          className="gdpr-image"
        />
        <h1 className="policy-text">GDPR PRIVACY POLICY</h1>
      </div>
      <div className="content-container">
        <h2>PURCHASING OF MERCHANDISE</h2>
        <p>
          Payment: If you purchase any merchandise through the TheSneakerCave
          Service, you will be required to provide TheSneakerCave information
          regarding your credit card or other payment instrument. You represent
          and warrant to TheSneakerCave that such information is true and that
          you are authorized to use the payment instrument. You will be
          responsible for all taxes associated with your purchase of merchandise
          through the Service.
        </p>

        <h2>CUSTOMER DATA COLLECTION AND PRIVACY POLICIES</h2>
        <p>
          The General Data Protection Regulation (GDPR) applies to all customer
          information collecting actions offered on TheSneakerCave.com. This is
          a data protection and privacy legislation in EU law for the European
          Union and the European Economic Area. The GDPR is an essential
          component of EU privacy legislation and human rights law, particularly
          Article 8 of the European Union’s Charter of Fundamental Rights.
        </p>
        <p>
          We are committed to maintaining the confidentiality of the information
          provided by the Customer while accessing the website{" "}
          <a href="https://TheSneakerCave.com/">TheSneakerCave.com</a>. Kindly
          read our “Privacy Policy” below before consenting to any inputs to
          better understand our commitment to respect and protect the rights of
          the Client:
        </p>

        <h3>1. Scope and purposes of personal information collection</h3>
        <p>
          All personal details including your name, phone number, and email
          address in the “Register”, “Contact,” or “Purchase” sections are only
          used for the following purposes: personalizing weekly newsletters
          based on client’s shopping history, enhancing customer support
          quality, providing news on sales and promotions based on preference,
          and further optimizing navigation on our website.
        </p>
        <p>
          We may also collect information of your activities on our website
          using “Google Analytics” and other data analysis tools provided by
          certified and verified third-party services. These data are simply
          used to track the traffic volumes to the products and services
          introduced on the website, as well as measure the level of customer
          interest in said products and services. The data we collect this way
          does not contain any user’s personal information. We solely use these
          parameters to monitor and optimize the website’s operations, enhance
          navigation, and produce a user-friendly interface for the clients.
        </p>

        <h3>Cookies</h3>
        <p>
          We collect and analyze IP addresses used to connect your devices to
          the internet using Cookies. Some connection information includes
          browser and browser version; operating system; date and time; and URLs
          accessed through our website. Cookies are unable to access or modify
          data on your device’s hard drive. Normally, you can configure your
          browser to disable cookies or notify you when they are sent. When you
          disable cookies, you can still visit the website{" "}
          <a href="https://thesneakercave.com/">TheSneakerCave.com</a>, but we
          will be unable to provide products and services that are appropriate
          for you, and we reserve the right to refuse your requests if there is
          a suspicion of a breach of our security.
        </p>

        <h3>2. Information Use Scope</h3>
        <p>
          We collect and use your personal information for the intended purpose
          and fully adhere to the terms of this “Privacy Policy.”
        </p>
        <p>
          We only share collected information with the following agencies,
          organizations, and individuals when necessary and legal: (i) Third
          parties with services integrated with the website{" "}
          <a href="https://thesneakercave.com/">TheSneakerCave.com</a> or a
          third party that we believe will meet the set objectives in collecting
          Customer’s personal information; (ii) Market research unit; and (iii)
          Competent state agencies.
        </p>

        <h3>3. Information storage time</h3>
        <p>
          There is no time limit except in the case that you send a cancellation
          request to us by calling or emailing the address shown on this
          website.
        </p>

        <h3>4. Address of information collection and management unit</h3>
        <p>
          Name of business: TheSneakerCave <br />
          Phone: +44 7453347455 <br />
          Email: support@thesneakercave.com
        </p>

        <h3>5. Personal data access and correction tools for customers</h3>
        <p>
          If you want to correct or update personal information such as your
          full name, email address, phone number, or address, you can do so on
          our website or contact us via the hotline: +44 7453347455 or send an
          email to support@thesneakercave.com
        </p>

        <h3>6. Commitment to maintaining the confidentiality of the customer’s personal information</h3>
        <p>
          We always make every effort to keep Customer information confidential
          and to use it to benefit the Customer as much as possible. We have
          security technologies and systems with numerous security features.
          However, no data transmitted over the internet can be guaranteed to be
          completely secure. As a result, we cannot guarantee that the
          information you provide to us will be completely secure, and we cannot
          be held liable in the event of unauthorized access.
        </p>
        <p>
          We reserve the right to make changes to the content on this website
          without prior notice. The Customer has agreed that by using our
          website after editing, the Customer acknowledges, agrees to comply
          with, and believes in this modification. As a result, before accessing
          other content on the website, customers should preview the content of
          this page. Except in the cases specified in this regulation or by law,
          we do not use, transfer, provide, or disclose personal information
          about customers to third parties without their consent.
        </p>

        <h3>7. Mechanism for receiving and resolving complaints about customer information</h3>
        <p>
          When you discover that your personal information is being used for the
          wrong purpose or scope, you can send a complaint email to
          support@thesneakercave.com or call +44 7453347455 to complain and
          provide us with information about the case. We promise to respond
          immediately or within 24 (twenty-four) business hours of receiving the
          complaint.
        </p>

        <h2>THESNEAKERCAVE PROPRIETARY RIGHTS</h2>
        <p>
          Service Content, Software and Trademarks: You are only authorized to
          use the TheSneakerCave Service for the purpose of engaging in business
          transactions with TheSneakerCave. You may not use any automated
          technology to scrape, mine or gather any information from the
          TheSneakerCave Service or otherwise access the pages of the
          TheSneakerCave Service for any unauthorized purpose. If you are
          blocked by TheSneakerCave from accessing the TheSneakerCave Service
          (including by blocking your IP address), you agree not to implement
          any measures to circumvent such blocking (e.g., by masking your IP
          address or using a proxy IP address). The technology and software
          underlying the TheSneakerCave Service or distributed in connection
          therewith are the property of TheSneakerCave, our affiliates and our
          partners (the “Software”).
        </p>
        <p>
          You agree not to copy, modify, create a derivative work of, reverse
          engineer, reverse assemble or otherwise attempt to discover any source
          code, sell, assign, sublicense, or otherwise transfer any right in the
          Software.
        </p>
        <p>
          TheSneakerCave Service may contain images, artwork, fonts and other
          content or features (“Service Content”) that are protected by
          intellectual property rights and laws. Except as expressly authorized
          by TheSneakerCave, you agree not to modify, copy, frame, rent, lease,
          loan, sell, distribute or create derivative works based on the
          TheSneakerCave Service or the Service Content, in whole or in part.
          Any use of the TheSneakerCave Service or the Service Content other
          than as specifically authorized herein is strictly prohibited. Any
          rights not expressly granted herein are reserved by TheSneakerCave.
        </p>
        <p>
          TheSneakerCave name and logos are trademarks and service marks of
          TheSneakerCave. Other company, product, and service names and logos
          used and displayed via the TheSneakerCave Service may be trademarks or
          service marks of their respective owners who may or may not endorse or
          be affiliated with or connected to TheSneakerCave. Nothing in these
          Terms of Service or the TheSneakerCave Service should be construed as
          granting any license or right to use any of TheSneakerCave Trademarks
          displayed on the TheSneakerCave Service, without our prior written
          permission in each instance. All goodwill generated from the use of
          TheSneakerCave Trademarks will inure to TheSneakerCave’ exclusive
          benefit.
        </p>

        <h3>Third Party Material</h3>
        <p>
          Under no circumstances will TheSneakerCave be liable in any way for
          any content or materials of any third parties (including users),
          including, but not limited to, for any errors or omissions in any
          content, or for any loss or damage of any kind incurred as a result of
          the use of any such content or materials. To the maximum extent
          permitted under applicable law, the third party providers of such
          content and materials are express and intended third party
          beneficiaries of these Terms of Services with respect to their content
          and materials.
        </p>
        <p>
          TheSneakerCave may preserve content and may also disclose content if
          required to do so by law or in the good faith belief that such
          preservation or disclosure is reasonably necessary to: (a) comply with
          legal process, applicable laws or government requests; (b) enforce
          these Terms of Service; (c) respond to claims that any content
          violates the rights of third parties; or (d) protect the rights,
          property, or personal safety of TheSneakerCave.
        </p>
      </div>
    </div>
  );
};

export default GDPR;
