import { categories } from './productsConstatns';

export const gridImages = [
    { id: 1, title: 'Make it happen with our freshest kicks.', image: require("../Image/Cricket-wepon/22.jpg"), link: '#' },
    { id: 2, title: 'Elevate your game with top notch quality models.', image: require("../Image/Cricket-wepon/25.jpg"), link: '#' }
  ];


export const mainBanner = {
    image: require('../Image/Cricket-wepon/04.jpg'),
    title: 'Yeezy',
    subTitle: 'Modern style that changes the game and breaks boundaries.',
}

export function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export function convertShoeSize(euSize) {
  // Define conversion logic based on standard conversion charts
  const usSize = euSize - 33; // This is a simplified conversion. The actual conversion may vary based on the brand and type of shoe.
  const ukSize = euSize - 33.5; // This is also a simplified conversion.

  // Format the output string
  const formattedString = `US ${usSize.toFixed(1)} / UK ${ukSize.toFixed(1)} / EU ${euSize}`;

  return formattedString;
}

export function euSizeToCentimeters(euSize) {
  const sizeChart = {
    35: 22,
    36: 22.5,
    37: 23.5,
    38: 24,
    39: 25,
    40: 25,
    41: 26,
    42: 26.5,
    43: 27.5,
    44: 28,
    45: 29,
    46: 30,
    47: 31,
};

  const lengthInCm = sizeChart[euSize];

  if (lengthInCm !== undefined) {
    return `${lengthInCm}cm`;
  } else {
    return "Size not found in chart";
  }
}
export function getRandomRange(array) {
  const arrayLength = array.length;

  if (arrayLength < 12) {
    return array;
  }

  const shuffledArray = shuffleArray(array);
  const start = Math.floor(Math.random() * (arrayLength - 12));
  return shuffledArray.slice(start, start + 12);
}
export function getLastRange(array) {
  const arrayLength = array.length;

  if (arrayLength < 12) {
    return array;
  }

  return array.slice(arrayLength - 12, arrayLength);
} 
export const slides = [
  {
    image: require("../Image/Cricket-wepon/18.jpg"),
    quote: "Enhance your streetwear with AJ 4 Retro – blending ultimate comfort and modern style",
    saleText: "AJ 4 Retro",
    productText: "AJ 4 Retro",
    href: categories[7]
  },
  {
    image: require("../Image/Cricket-wepon/24.jpg"),
    quote: "Elevate your streetwear game with AJ Retro 1 – where comfort meets cutting-edge style",
    saleText: "AJ Retro 1",
    productText: "AJ Retro 1",
    href: categories[8]
  },
  {
    image: require("../Image/Cricket-wepon/11.jpg"),
    quote: "Embrace the day with NK Air Max – the peak of cutting-edge design and performance.",
    saleText: "NK Air Max",
    productText: "NK Air Max",
    href: categories[4]
  },
  {
    image: require("../Image/Cricket-wepon/29.jpg"),
    quote: "Experience unparalleled comfort and timeless style with NB – the perfect fusion of tradition and innovation.",
    saleText: "NB ",
    productText: "NB",
    href: categories[9]
  },
  {
    image: require("../Image/Cricket-wepon/28.jpg"),
    quote: "Experience the ultimate in style and comfort with Yeezy, available at the best prices you’ll find.",
    saleText: "YEEZY BOOST 350 EXCLUSIVE",
    productText: "Yeezy Boost 350",
    href: categories[0]
  },
  {
    image: require("../Image/Cricket-wepon/26.jpg"),
    quote: "Step into the future with NK Scorpion – the pinnacle of innovative design and performance.",
    saleText: "NK Scorpion",
    productText: "NK",
    href: categories[2]
  },
  {
    image: require("../Image/Cricket-wepon/19.jpg"),
    quote: "Upgrade your look with NK Air Force 1 – merging comfort and cutting-edge fashion.",
    saleText: "NK Air Force 1",
    productText: "NK AF1",
    href: categories[3]
  },
];
export const dummySlides = [
  {
    image: require("../Image/DummyImages/1.jpg"),
    quote: "Step up your streetwear game with unmatched style and comfort.",
    saleText: "Exclusive Streetwear Collection",
    productText: "Premium Streetwear",
    href: "Sneaker"
},
{
    image: require("../Image/DummyImages/2.jpg"),
    quote: "Reinvent your look with the perfect blend of classic design and modern flair.",
    saleText: "Timeless Fashion Collection",
    productText: "Elevated Style",
    href: "Sneaker"
},
{
    image: require("../Image/DummyImages/3.jpg"),
    quote: "Unleash your potential with cutting-edge design and performance.",
    saleText: "Performance Wear Collection",
    productText: "Active Performance Gear",
    href: "Sneaker"
},
{
    image: require("../Image/DummyImages/4.jpg"),
    quote: "Discover the perfect balance of comfort and style, redefining your everyday look.",
    saleText: "Ultimate Comfort Collection",
    productText: "Everyday Essentials",
    href: "Sneaker"
},
];

export const slides2 = [
  {
    image: require("../Image/Cricket-wepon/15.jpg"),
    quote: "Elevate your streetwear with AJ 4 Retro – a perfect blend of comfort and style.",
    saleText: "AJ 4 Retro",
    productText: "AJ 4 Retro",
    href: categories[7]
  },
  {
    image: require("../Image/Cricket-wepon/23.jpg"),
    quote: "Upgrade your look with AJ Retro 1 – merging comfort and cutting-edge fashion.",
    saleText: "AJ Retro 1",
    productText: "AJ Retro 1",
    href: categories[8]
  },
  {
    image: require("../Image/Cricket-wepon/21.jpg"),
    quote: "Conquer the day with NK Air Max – at the forefront of design and performance.",
    saleText: "NK Air Max",
    productText: "NK Air Max",
    href: categories[4]
  },
  {
    image: require("../Image/Cricket-wepon/20.jpg"),
    quote: "Discover unmatched comfort and timeless style with NB – where tradition meets innovation.",
    saleText: "NB",
    productText: "NB",
    href: categories[9]
  },
  {
    image: require("../Image/Cricket-wepon/13.jpg"),
    quote: "Experience the ultimate in style and comfort with NK Air Force 1, available at the best prices you’ll find.",
    saleText: "NK Air Force 1",
    productText: "NK AF1",
    href: categories[3]
  }
];


export const sliderData = [
  {
      name: "AF1",
      image: require('../Image/home/11.png'),
      url: `${categories[4]}`
  },
  {
      name: " AJ4",
      image: require('../Image/home/2.png'),
      url: `${categories[8]}`
  },
  {
      name: " AJ1",
      image: require('../Image/home/3.png'),
      url: `${categories[8]}`
  },
  // {
  //     name: "NK Scorpion",
  //     image: require('../Image/home/4.png'),
  //     url: `${categories[2]}`
  // }
  ,
  {
      name: "Yeezy Boost",
      image: require('../Image/home/4.png'),
      url: `${categories[0]}`
  },
  {
    name: "NK Vapormax",
    image: require('../Image/home/12.png'),
    url: `${categories[6]}`
  },
  {
    name: "NK Air Max",
    image: require('../Image/home/6.png'),
    url: `${categories[4]}`
  }
];
