import React from "react";
import { ReactComponent as CricketBall } from "../../../Image/Loader-svg/LoaderBlack.svg";
import "./Loader.css";

const CricketBallLoader = ({badConnection}) => (
  <div className="cricket-ball-loader">
    <CricketBall className="spinner" />
    {badConnection ? <h2 style={{ textAlign: 'center'}}>Check your network connection.</h2> : <></>}
  </div>
);

export default CricketBallLoader;
