import React, { useEffect, useState } from "react";
import "./Cart.css";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart, removeItemFromCart, toggleHomeDelivery } from "../../actions/cartAction";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MataData/MataData";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CartItem from "./CartItem";
import {
  dispalyMoney,
  generateDiscountedPrice,
} from "../DisplayMoney/DisplayMoney";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rememberMeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "10px",
    "& .MuiIconButton-label": {
      color: "black",
    },
    marginBottom: "10px",
  },
}));
const Cart = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartItems, homeDelivery } = useSelector((state) => state.cart);
  const [deliveryPrice, setDeliveryPrice] = useState(10);

  // // new code
  // const [couponCode, setCouponCode] = useState("");
  // const [isFocused, setIsFocused] = useState(false);
  // const [isValid, setIsValid] = useState(true);

  // new code end

  useEffect(() => {
    if (homeDelivery) {
      setDeliveryPrice(15);
    } else {
      setDeliveryPrice(10);
    }
  }, [homeDelivery]);

  const increaseQuantity = (id, size) => {
    dispatch(addItemToCart(id, size, true));
  };

  const decreaseQuantity = (id, size) => {
    dispatch(addItemToCart(id, size, false));
  };

  // // new code
  // const handleApplyCoupon = () => {
  //   // handle apply coupon logic
  //   setIsValid(false);
  // };

  // const handleFocus = (event) => {
  //   setIsFocused(event.target.value !== "");
  // };

  // new code end

  function handleHomeDelivery(){
    dispatch(toggleHomeDelivery());
  }

  const deleteCartItems = (id, size, name) => {
    dispatch(removeItemFromCart(id, size));
  };

  const checkoutHandler = () => {
    console.log("Checkout")
    history.push("/shipping");
  };

  // claculte price after discount
  let totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const priceNum = Number(totalPrice);
  let discountedPrice = generateDiscountedPrice(totalPrice);
  let totalDiscount = totalPrice - discountedPrice;
  let final = totalPrice - totalDiscount;
  final = dispalyMoney(final);
  totalDiscount = dispalyMoney(totalDiscount);
  totalPrice = dispalyMoney(totalPrice);

  return (
    <>
      <div className="cartPage"> 
      <MetaData title="Your Cart" />  
        <div className="cart_HeaderTop">
          <div className="headerLeft">
            <Typography variant="h5" component="h1" className="cartHeading">
              Basket
            </Typography>
            <Typography variant="body2" className="cartText3">
              Subtotal ({`${cartItems?.length} ${cartItems?.length > 1 ? ' items' : 'item'}`}) <b>{totalPrice}</b>
            </Typography>
          </div>
          <Typography
            variant="body2"
            className="cartText2"
            onClick={() => history.push("/shop")}
          >
            Continue exploring
          </Typography>
        </div>

        <div className="separator_cart2"></div>

        {cartItems?.length === 0 ? (
          <div className="emptyCartContainer">
            <RemoveShoppingCartIcon className="cartIcon" />

            <Typography variant="h5" component="h1" className="cartHeading">
              Your basket is empty
            </Typography>
            <Typography variant="body" className="cartText">
              You have no items in your basket.
            </Typography>
            <Typography variant="body" className="cartText">
              You can add items to your basket by clicking the button below
            </Typography>
            <Link to="/shop">
              <Button className="shopNowButton">Explore Now.</Button>
            </Link>
          </div>
        ) : (
          <>
            <div className="cart_content_wrapper">
              <div className="cart_left_container">
                {cartItems &&
                  cartItems.map((item) => (
                    <Link
                      to="#"
                      style={{ textDecoration: "none", color: "none", width: "95%" }}
                    >
                      <CartItem
                        key={item.productId}
                        item={item}
                        deleteCartItems={deleteCartItems}
                        decreaseQuantity={decreaseQuantity}
                        increaseQuantity={increaseQuantity}
                        length={cartItems?.length}
                        id = {item.productId}
                      />
                    </Link>
                  ))}
              </div>

              <div className="separator_cart3"></div>
              <div className="cart_right_container">
                <div className="order_summary">
                  <h4>
                    Order &nbsp; ( {cartItems?.length}{" "}
                    {cartItems?.length > 1 ? "items" : "item"} )
                  </h4>
                  <br/>
                  <div className="order_summary_details">
                  <div className="delivery order_Summary_Item">
                      <span>Price</span>
                      <p>
                        <b>{dispalyMoney(priceNum)}</b>
                      </p>
                    </div>

                    {/* <div className="discount order_Summary_Item">
                      <span>Discount</span>
                      <p>
                        <del>{totalDiscount}</del>
                      </p>
                    </div> */}
                    {/* <div className="delivery order_Summary_Item">
                      <span>Delivery Type</span>
                    </div>
                    <Grid container className={classes.rememberMeContainer}>
                      <div className="delivery order_Summary_Item">
                        <Grid item>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            onChange={handleHomeDelivery}
                            checked={homeDelivery}
                            label="До адрес (с фирма Еконт)"
                          />
                        </Grid>
                        <p className="added-label">
                          <b>15€*</b>
                        </p>
                      </div>
                      <div className="delivery order_Summary_Item">
                        <Grid item>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            onChange={handleHomeDelivery}
                            checked={!homeDelivery}
                            label="До офис на Еконт"
                          />
                        </Grid>
                        <p className="added-label">
                          <b>10€*</b>
                        </p>
                      </div>
                    </Grid> */}
                    <div className="delivery order_Summary_Item">
                      <span>Shipping</span>
                      <p>
                        <b>Free</b>
                      </p>
                    </div>

                    <div className="separator_cart"></div>
                    <div className="total_price order_Summary_Item">
                      <div>
                        <h4 style={{ marginBottom: "5px"}}>Subtotal</h4>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "-10px",
                            color: "#414141",
                          }}
                        >
                          (VAT included)
                        </p>
                      </div>
                      <p>
                        <b>{priceNum}€</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="separator"></div>

                {/* <div className="coupon-box-wrapper">
                  <div
                    className={`coupon-box-content ${
                      isFocused ? "focused" : ""
                    }`}
                  > */}
                    {/* <TextField
                      label="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      onFocus={handleFocus}
                      onBlur={() => setIsFocused(false)}
                      error={!isValid}
                      helperText={!isValid && "Invalid coupon code"}
                      variant="outlined"
                      size="small"
                      style={{ width: "200px", marginRight: "1rem" }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className="coupon-box-apply-btn"
                      onClick={handleApplyCoupon}
                    >
                      Apply
                    </Button> */}
                  {/* </div>
                </div> */}

                <Button
                  variant="contained"
                  className="btn-custom"
                  onClick={checkoutHandler}
                >
                  Continue to checkout
                </Button>

                {/* <div className="paymentLogoImg">
                  <img
                    src={require("../../Image/cart/cart_img.png")}
                    alt="payemnt-icons"
                    className="paymentImg"
                  />
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
