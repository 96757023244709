import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layouts/MataData/MataData";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import OrderDetailsSection from "./OrderDetails";
import { clearErrors, createOrder } from "../../actions/orderAction";
import CheckoutSteps from "./CheckoutSteps.jsx";

// for cardDetails for card details input section and hooks for accessing stripe and element from App.js route
import "./Cart.css";
import {
  Typography,
  TextField,
  Grid,
  Radio,
  Button,
  Divider,
  Link,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  CreditCard,
  CardMembership,
  Payment,
  Lock,
} from "@material-ui/icons";
import { FaUserSecret } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import { ReactComponent as MasterCard } from "../../Image/payment-svg/mastercard.svg";
import { ReactComponent as Visa } from "../../Image/payment-svg/visa (1).svg";
import { ReactComponent as Paytm } from "../../Image/payment-svg/paytm.svg";
import Stripe from "../../Image/Footer/Stripe.png";
import {
  dispalyMoney,
  generateDiscountedPrice,
} from "../DisplayMoney/DisplayMoney";
import { useTheme } from "@emotion/react";
import PayPalIntegration from "../../constants/PayPal";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import DummyCard from "./DummyCard.jsx";
import { fetchCountryCode } from "../../constants/location.js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm.jsx";

const useStyles = makeStyles((theme) => ({
  payemntPage: {
    padding: "1rem 0",
    width: "100%",
    backgroundColor: "white",
    overflow: "hidden",
  },

  paymentPage__container: {
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  icons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  PaymentBox: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0.5rem", // Corrected the property name
    overflow: "hidden", // Corrected the property name
    backgroundColor: "white",
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
      // position: "fixed",
      bottom: 0,
      left: 0,
      marginTop: 0,
      boxShadow: "0 -2px 5px rgba(0,0,0,0.2)", // Add shadow to separate from content
    },
  },
  fixedButton: {
    display: "block",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "fixed",
      bottom: 0,
      left: 0,
      padding: "1rem",
    },
  },
  PaymentHeading: {
    fontWeight: "800",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
  securePayemnt: {
    display: "flex",
    alignItems: "center",
    fontWeight: "300",
    backgroundColor: "#f5f5f5 !important",
    width: "90%",
    padding: "1rem",
    gap: "0.8rem",
    marginBottom: "1rem",
    borderRadius: "5px",
    "& svg": {
      fontSize: "2rem",
    },
  },
  gridcheckbox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "3rem",
  },
  checkbox: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    marginTop: theme.spacing(1),
    "& .MuiIconButton-label": {
      color: "black",
    },
  },
  rememberMeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "10px",
    "& .MuiIconButton-label": {
      color: "black",
    },
  },
  order_Details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2rem 0.5rem 2rem 0.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  termsAndConditionsText: {
    fontFamily: "Asul",
    color: "#727272",
    fontWeight: "400",
    lineHeight: "17px",
    paddingLeft: "16px",
    fontSize: "12px",
  },
  privacyText: {
    marginLeft: "4px",
    textDecoration: "underline",
    color: "black",
    fontSize: "14px",
    "&:hover": {
      color: "#00aaa4",
    },
  },
  placeOrderBtn: {
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: "500",
    fontSize: "1rem",
    padding: "0.8rem 1rem",
    borderRadius: "0.5rem",
    width: "100%",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#645bfb",
      transition: "0.3s",
      animation: "ease-in-out",
    },
  },
  payemntAmount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "fit-content",
    padding: "1rem 0.5rem 0 0.5rem",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  orderSub_heading: {
    fontWeight: "600",
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  boldDivider: {
    borderBottom: `0.3px solid #3A3E3A`,
    margin: "5px 0",
    width: "99%",
  },
  shipping_Deatils: {
    display: "flex",
    flexDirection: "column",
    width: "98%",
    padding: "1rem 1px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1rem 2rem",
    },
  },
  shipping_Address: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  shipping_Address_Details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "300",
    padding: "10px 0px",
    width: "70%",
  },
  shipping_Address_edit: {
    paddingRigth: "1rem",
    "& svg": {
      fontSize: "1.8rem",
      cursor: "pointer",
      color: "black",
      "&:hover": {
        color: "#00aaa4",
      },
    },
  },
  termsAndConditionsText: {
    fontFamily: "Asul",
    color: "#727272",
    textAlign: "center",
    lineHeight: "17px",
    paddingLeft: "4px",
    marginTop: theme.spacing(2),
    fontSize: "12px",
  },
  content: {
    paddingBottom: "6rem", // Space for fixed button on mobile
  },
  cardField: {
    display:"flex",
    flexDirection: "row"
  },
  // expiryInput:{
  //   display: "flex",
  //   flexDirection: "row",
  // },
  cvvInput: {
    display: "flex",
    alignItems: "center",
    width: "100%", // Make sure the input takes up available width
    gap: "0.5rem", // Add a small gap between icon and input field
  },
  
  paymentInput2: {
    flex: 1, // Ensure the input field expands to fill the remaining space
    padding: "0.5rem",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    outline: "none",
    width: "100%", // Make sure the input takes up available width
  }
}));
// This is your test publishable API key.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const appearance = {
  theme: 'stripe',
};
// Enable the skeleton loader UI for optimal loading.
const loader = 'auto';
const PaymentComponent = () => {
  const classes = useStyles();
  const [clientSecret,setClientSecret]=useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [nameOnCard, setNameOnCard] = React.useState("");
  const [couponCode, setCouponCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showDummyCard, setShowDummyCard] = useState(false)
  const [countryCode, setCountryCode] = useState(null);
  const { shippingInfo, cartItems, homeDelivery } = useSelector(
    (state) => state.cart
  );
  const { user, loading } = useSelector((state) => state.userData);

  const { error } = useSelector((state) => state.newOrder);

  const subTotal = cartItems.reduce((acc, currItem) => {
    return acc + currItem.quantity * currItem.price;
  }, 0);
  const totalFinalPrice = subTotal;

  const handleNameOnCardChange = (e) => {
    setNameOnCard(e.target.value);
  };

  const handleSetCouponCode = async () => {
    const code = await fetchCountryCode();

    setCountryCode(code || "EN");
  }

  useEffect(() => {
    handleSetCouponCode();
  }, [])

  // const handleApplyCoupon = () => {
  //   // handle apply coupon logic
  //   setIsValid(false);
  // };

  // const handleFocus = (event) => {
  //   setIsFocused(event.target.value !== "");
  // };


  
  //   const handleRadioChange = () => {
  //     setShowDummyCard(!showDummyCard);
  //   };

  //   const handleCloseDummyCard = () => {
  //     setShowDummyCard(false);
  //   };

  const address = `${shippingInfo.address} , ${shippingInfo.city} ${
    shippingInfo.state ? shippingInfo.state + ', ' : ''
  } ${shippingInfo.pinCode ? shippingInfo.pinCode + ', ' : ''} ${shippingInfo.country || "Bulgaria"}`;

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: subTotal,
    shippingPrice: 0,
    totalPrice: totalFinalPrice,
    delivery: 0,
    user: user,
  };

  const paymentData = {
    // stripe takes payment in pese there for multiply with 100 bcz 1rs == 100 pese
    amount: Math.round(totalFinalPrice * 100),  
  };

  async function paymentSubmitHandler() {
    // if(nameOnCard === ""){
    //   alert.error("Please enter name on card");
    //   return;
    // }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/payment/process`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
  
      const data = await response.json();
  
      // client_secret is key from STRIPE  while making payement post req at backend
      const client_secret = data.client_secret;

      setClientSecret(client_secret);
      // // passed at App.js route statement
      // if (!stripe || !elements) return;

      // // this object is from stripe-js. only values need to put
      // const result = await stripe.confirmCardPayment(client_secret, {
      //   payment_method: {
      //     card: elements.getElement(CardNumberElement),
      //     billing_details: {
      //       name: user.name,
      //       email: user.email,
      //       address: {
      //         line1: shippingInfo.address,
      //         state: shippingInfo.state,
      //         postal_code: shippingInfo.pinCode,
      //         country: countryCode,
      //       },
      //     },
      //   },
      // });

      // if (result.error) {
      //   // if error then again enable the button on

      //   alert.error(result.error.message);
      // } else {
      //   if (result.paymentIntent.status === "succeeded") {
      //     // add new property inside order object
      //     order.paymentInfo = {
      //       id: result.paymentIntent.id,
      //       status: result.paymentIntent.status,
      //     };
      //     alert.success(result.paymentIntent.status);

      //     window.fbq("track", "Purchase", {
      //       content_ids: cartItems.map((item) => item.productId),
      //       currency: "EUR",
      //       value: totalFinalPrice,
      //       content_type: "product",
      //     });
        
      //     dispatch(createOrder(order)); // Wait for the order to be created   
          
      //     history.push("/success");
      //   } else {
      //     alert.error("There's some issue while processing payment");
      //   }
      // }
    } catch (error) {
      // if error while payment then again enable payment button

    
      alert.error(error.message);
    }
  }
  async function dummyPaymentSubmitHandler() {
    try {
      dispatch(createOrder(order)); // Wait for the order to be created
      history.push("/success");
    } catch (error) {
      // if error while payment then again enable payment button
  
      alert.error(error.message);
    }
  }

  useEffect(()=>{
    paymentSubmitHandler();
  },[])

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error]);

  // calculate price after discount
  let totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  let discountedPrice = generateDiscountedPrice(totalPrice);
  let totalDiscount = totalPrice - discountedPrice;
  totalDiscount = dispalyMoney(totalDiscount);
  totalPrice = dispalyMoney(totalPrice);

  return (
    <>
      <div className={classes.payemntPage}>
        <CheckoutSteps activeStep={2} />
        <MetaData title={"Payment"} />
        <div className={classes.paymentPage__container}>
          <div className={classes.payemntAmount}>
            <div className="order_summary">
              <h4>
                Order &nbsp; ( {cartItems?.length}{" "}
                {cartItems?.length > 1 ? "items" : "item"} )
              </h4>
              <br />
              <div className="order_summary_details">
                <div className="delivery order_Summary_Item">
                  <span>Price</span>
                  <p>
                    <b>{subTotal}€</b>
                  </p>
                </div>
                <div className="delivery order_Summary_Item">
                  <span>Shipping</span>
                  <p>
                    <b>Free</b>
                  </p>
                </div>

                <div className="separator_cart"></div>
                <div className="total_price order_Summary_Item">
                  <div>
                    <h4 style={{ marginBottom: "5px" }}>Subtotal</h4>

                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "-10px",
                        color: "#414141",
                      }}
                    >
                      (VAT included)
                    </p>
                  </div>
                  <p>
                    <b>{totalFinalPrice}€</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="separator"></div>

            <div className={classes.order_Details}>
              <h5 className={classes.orderSub_heading}>ORDER DETAILS</h5>
              {cartItems &&
                cartItems.map((item, idx) => (
                  <Link
                    to={`/product/${item.productId}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <OrderDetailsSection
                      key={idx}
                      item={item}
                      totalDiscount={totalDiscount}
                      totalPrice={totalPrice}
                    />
                  </Link>
                ))}
            </div>
            <Divider className={classes.boldDivider} />
            <div className={classes.shipping_Deatils}>
              <Typography variant="h6" className={classes.orderSub_heading}>
                SHIPPING ADDRESS
              </Typography>

              <div className={classes.shipping_Address}>
                <div className={classes.shipping_Address_Details}>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    {user?.name && user?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    {address}
                  </Typography>
                </div>
                <div className={classes.shipping_Address_edit}>
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => {
                      history.push("/shipping");
                    }}
                  />
                </div>
              </div>
              <Typography
                variant="subtitle2"
                className={classes.mobileNo}
                style={{
                  fontWeight: 400,
                  marginTop: "-5px",
                  fontSize: "16px",
                }}
              >
                {shippingInfo.phoneNo},
              </Typography>

              <Typography
                variant="subtitle2"
                className={classes.emailAddress}
                style={{ fontWeight: 400, fontSize: "16px" }}
              >
                {user?.email || shippingInfo?.email}
              </Typography>
            </div>

            <div className={classes.shipping_Deatils}>
              <Typography
                variant="h6"
                className={classes.orderSub_heading}
                style={{ marginTop: "5px" }}
              >
                DETAILS
              </Typography>
              <div className={classes.shipping_Address}>
                <div className={classes.shipping_Address_Details}>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    {user?.name || shippingInfo?.firstName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    {address}
                  </Typography>
                </div>
                <div className={classes.shipping_Address_edit}>
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => {
                      history.push("/shipping");
                    }}
                  />
                </div>
              </div>
              <Typography
                variant="subtitle2"
                className={classes.mobileNo}
                style={{
                  fontWeight: 400,
                  marginTop: "-5px",
                  fontSize: "16px",
                }}
              >
                {shippingInfo.phoneNo},
              </Typography>

              <Typography
                variant="subtitle2"
                className={classes.emailAddress}
                style={{ fontWeight: 400, fontSize: "16px" }}
              >
                {user?.email || shippingInfo?.email}
              </Typography>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Typography
                variant="body2"
                style={{ marginTop: "1rem", paddingBottom: "5vh" }}
                className={classes.termsAndConditionsText}
              >
                When you make an order, you agree to our
                <Link href="/terms/service" className={classes.privacyText}>
                  Terms Of Service
                </Link>
              </Typography>
            </div>
          </div>
          <div className={classes.PaymentBox}>
                <Typography
                  variant="h5"
                  component="h1"
                  className={classes.PaymentHeading}
                >
                  Payment method
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.securePayemnt}
                >
                  <AssuredWorkloadOutlinedIcon />
                  Payments are SSL encrypted so that your credit card and
                  payment details stay safe.
                </Typography>
               

                <div className={classes.cardContainer}>
                  <Typography variant="h6" className={classes.subHeading}>
                    Credit / Debit Card <CreditCard fontSize="medium" />
                  </Typography>
              {clientSecret && (
                <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
                  <CheckoutForm totalFinalPrice={totalFinalPrice}  cartItems={cartItems} order={order}  />
                 
                </Elements>
              )}
             
                </div>
              </div>
        </div>
      </div>
    </>
  );
};

export default PaymentComponent;
