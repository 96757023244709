import React from "react";
import "./Privacy.css"; // Keeping the same CSS as before

const AboutUs = () => {
  return (
    <div className="privacy-policy-container">
      <div className="container___">
        <h2>About Us</h2>
        <p>
          <strong>TheSneakerCave</strong> is an online store for sneakers that
          offers a wide range of the freshest kicks on the market. In our catalog, you
          will find sneaker products, beyond your imagination. Whether you are an avid collector or looking
          for the perfect pair for everyday wear,{" "}
          <strong>TheSneakerCave</strong> is the place where you will find
          everything you need.
        </p>
        <p>
          Our mission is to provide you with the best models on the market,
          combining quality, style, and comfort. Visit us and enjoy exceptional
          service and fast delivery.
        </p>

        <h2>Who Are We?</h2>
        <p>
          <strong>TheSneakerCave</strong> was founded by a team of sneaker
          enthusiasts who share a common passion for unique design and
          high-quality sports footwear. We believe that sneakers are not just
          shoes, but an expression of personal style and lifestyle.
        </p>
        <p>
          Our team carefully selects each model to offer you the latest and most
          sought-after sneakers on the market. We strive to stay up-to-date with
          the latest trends and offer something for every taste – from classic
          and timeless models to the most modern and extravagant designs.
        </p>
        <p>
          At <strong>TheSneakerCave</strong>, we pride ourselves on our
          commitment to customers. We are here to help you with professional
          advice and personalized recommendations to find the perfect pair of
          sneakers. Our goal is to make every shopping experience pleasant and
          easy.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
