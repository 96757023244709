export const fetchCountryCode = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      
      if (!response.ok) {
        throw new Error('Failed to fetch country code');
      }
  
      const data = await response.json();
      
      return data.country_code; // This returns the 2-letter country code (e.g., 'US' for the United States)
    } catch (error) {
      console.error('Error fetching country code:', error);
      return null;
    }
  };
  