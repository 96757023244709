import axios from "axios";
import { RESET_CART } from "../constants/cartConstant";
import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  CLEAR_ERRORS,
  MY_ORDER_REQUEST,
  MY_ORDER_SUCCESS,
  MY_ORDER_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_FAIL,
  ALL_ORDERS_SUCCESS,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  ALL_BACKUP_ORDERS_FAIL,
  ALL_BACKUP_ORDERS_SUCCESS,
  ALL_BACKUP_ORDERS_REQUEST,
  BACKUP_ORDER_DETAILS_REQUEST,
  BACKUP_ORDER_DETAILS_SUCCESS,
  BACKUP_ORDER_DETAILS_FAIL,
} from "../constants/orderConstant";

export const createOrder = (order) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ORDER_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/order/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
      credentials: "include",
    })
    // .then(async () => await updateOrderProducts(order));

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: CREATE_ORDER_FAIL, payload: error.message });
  } finally {
    dispatch({ type: RESET_CART })
  }
}; 

// get all orders
export const myOrders = () => async (dispatch) => {
  try {
    dispatch({ type: MY_ORDER_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/orders/myOrders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    });

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: MY_ORDER_SUCCESS,
      payload: data.userOrders,
    });
  } catch (error) {
    dispatch({ type: MY_ORDER_FAIL, payload: error.message });
  }
};

// get single order

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/order/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    });

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data.order,
    });
  } catch (error) {
    dispatch({ type: ORDER_DETAILS_FAIL, payload: error.message });
  }
};

export const getBackupOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BACKUP_ORDER_DETAILS_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/backupOrder/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    });

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: BACKUP_ORDER_DETAILS_SUCCESS,
      payload: data.order,
    });
  } catch (error) {
    dispatch({ type: BACKUP_ORDER_DETAILS_FAIL, payload: error.message });
  }
};

export const getAllOrders = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_ORDERS_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/orders`, {
      withCredentials: true // Include credentials (cookies, HTTP authentication)
    });

    dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
  } catch (error) {
    dispatch({ type: ALL_ORDERS_FAIL, payload: error.message });
  }
};
export const getAllBackupOrders = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_BACKUP_ORDERS_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/backupOrders`, {
      withCredentials: true // Include credentials (cookies, HTTP authentication)
    });

    dispatch({ type: ALL_BACKUP_ORDERS_SUCCESS, payload: data.orders });
  } catch (error) {
    dispatch({ type: ALL_BACKUP_ORDERS_FAIL, payload: error.message });
  }
};


// delet Order --> admin
export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ORDER_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/order/${id}`, {
      method: "DELETE",
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    });

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({ type: DELETE_ORDER_FAIL, payload: error.message });
  }
};

// update order --> admin (status update) 
export const updateOrder = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORDER_REQUEST });

    const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/order/${id}`, {
      method: "PUT",
      body: productData,
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    });

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({ type: UPDATE_ORDER_FAIL, payload: error.message });
  }
};

// clear errors

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

const updateOrderProducts = async (order) => {
  const groupedItems = {};

  // Group items by productId
  order.orderItems.forEach(item => {
    if (!groupedItems[item.productId]) {
      groupedItems[item.productId] = [];
    }
    groupedItems[item.productId].push(item);
  });

  const updatePromises = Object.keys(groupedItems).map(async productId => {
    try {
      // Fetch product details
      const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/product/${productId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json();
      const productDetails = data.Product;

      // Update stock for each size
      groupedItems[productId].forEach(item => {
        if (productDetails.Stock[item.size] !== undefined) {
          productDetails.Stock[item.size] -= item.quantity;
        }
      });

      // Send update request
      const updateResponse = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/productStock/${productDetails._id}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productDetails),
        credentials: 'include',
      });

      if (!updateResponse.ok) {
        const message = `An error has occurred: ${updateResponse.status}`;
        throw new Error(message);
      }

      const updateData = await updateResponse.json();
      return updateData;
    } catch (error) {
      console.error(`Error fetching or updating product ${productId}:`, error);
    }
  });

  // Wait for all update requests to complete
  const results = await Promise.all(updatePromises);
  return results;
};
