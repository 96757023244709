import {
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_FAIL,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  CLEAR_ERRORS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DETAILS_FAIL,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  NEW_REVIEW_RESET,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  NEW_PRODUCT_RESET,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL, 
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_RESET,
  SHUFFLED_PRODUCTS_FAIL,
  LATEST_PRODUCTS_FAIL,
  SHUFFLED_PRODUCTS_SUCCESS,
  LATEST_PRODUCTS_SUCCESS,
  SHUFFLED_PRODUCTS_REQUEST,
  LATEST_PRODUCTS_REQUEST,
  ADMIN_DUMMY_PRODUCT_SUCCESS,
  ADMIN_DUMMY_PRODUCT_REQUEST,
  UPDATE_DUMMY_PRODUCT_RESET,
  DELETE_DUMMY_PRODUCT_RESET,
  UPDATE_DUMMY_PRODUCT_FAIL,
  DELETE_DUMMY_PRODUCT_FAIL,
  DELETE_DUMMY_PRODUCT_SUCCESS,
  UPDATE_DUMMY_PRODUCT_SUCCESS,
  DELETE_DUMMY_PRODUCT_REQUEST,
  UPDATE_DUMMY_PRODUCT_REQUEST,
  NEW_DUMMY_PRODUCT_REQUEST,
  NEW_DUMMY_PRODUCT_SUCCESS,
  NEW_DUMMY_PRODUCT_FAIL,
  NEW_DUMMY_PRODUCT_RESET,
  SET_CLOAKED,
  decryptCookieValue,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  UPDATE_COUNTRIES_REQUEST,
  UPDATE_COUNTRIES_SUCCESS,
  UPDATE_COUNTRIES_FAIL,
  UPDATE_COUNTRIES_RESET,
} from "../constants/productsConstatns";

// Read the 'cloaked' cookie from document.cookie
export const getCloakedFromCookie = () => {
  const cloakedCookie = document.cookie.split('; ').find(row => row.startsWith(`identity=`));
  if (cloakedCookie) {
      const encryptedValue = cloakedCookie.split('=')[1];
      try {
          const decryptedValue = decryptCookieValue(encryptedValue, '46kU5A0JQv5C0jrzVJua03HfnrUfVI00'); // Use the same secret key
          return decryptedValue === 'true'; // Return true or false based on the decrypted value
      } catch (error) {
          console.error('Failed to decrypt cloaked cookie:', error);
          return true; // Default to true if decryption fails
      }
  }
  return true; // Default to true if the cookie is not found
};

export const productsReducer = (
  state = { cloaked: getCloakedFromCookie() || true, products: [], shuffledProducts: [], latestProducts: [], filteredProductCount: 0, productsCount: 0 },
  action
) => {
  switch (action.type) {
    // Handle all product and admin product requests
    case ALL_PRODUCT_REQUEST:
    case ADMIN_PRODUCT_REQUEST:
    case SHUFFLED_PRODUCTS_REQUEST:
    case LATEST_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        products: [],
      };

    // Handle admin product success
    case ADMIN_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };

    // Handle all product success
    case ALL_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        productsCount: action.payload.productsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredProductCount: action.payload.filteredProductCount,
      };

    // Handle shuffled products success
    case SHUFFLED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shuffledProducts: action.payload,
      };

    // Handle latest products success
    case LATEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        latestProducts: action.payload,
      };

    // Handle failures for all product actions
    case ALL_PRODUCT_FAIL:
    case ADMIN_PRODUCT_FAIL:
    case SHUFFLED_PRODUCTS_FAIL:
    case LATEST_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    // Clear error
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case SET_CLOAKED:
      return {
        ...state,
        cloaked: action.payload,
      }

    // Default case
    default:
      return state;
  }
};

// product detalis  :
export const productDetailsReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        product: action.payload, // product details from backend
        success: true,
      };
    case PRODUCT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,

      };
      case PRODUCT_DETAILS_RESET:
        return {
         success: false,
        ...state,
        };

    // error msg clear
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// new Review Reducer
export const newReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_REVIEW_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };

    case NEW_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case NEW_REVIEW_RESET:
      return {
        ...state,
        success: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

//cretae a product reducer

export const newProductReducer = (state = { newProductData: [] }, action) => {
  switch (action.type) {
    case NEW_PRODUCT_REQUEST: {
      return { loading: true };
    }

    case NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        newProductData: action.payload.data,
      };

    case NEW_PRODUCT_FAIL: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    case NEW_PRODUCT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

// delte and Upadate reducer :

export function deleteUpdateReducer(state = { product: {} }, action) {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case DELETE_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

// get all review Reducer =>

export const getALLReviewReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case ALL_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
      };
    case ALL_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// delete review reducer  =>

export const deleteReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case DELETE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_REVIEW_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const dummyProductsReducer = (
  state = { products: [], shuffledProducts: [], latestProducts: [], filteredProductCount: 0, productsCount: 0 },
  action
) => {
  switch (action.type) {
    // Handle all product and admin product requests
    case ALL_PRODUCT_REQUEST:
    case ADMIN_DUMMY_PRODUCT_REQUEST:
    case SHUFFLED_PRODUCTS_REQUEST:
    case LATEST_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        products: [],
      };

    // Handle admin product success
    case ADMIN_DUMMY_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };

    // Handle all product success
    case ALL_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        productsCount: action.payload.productsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredProductCount: action.payload.filteredProductCount,
      };

    // Handle shuffled products success
    case SHUFFLED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shuffledProducts: action.payload,
      };

    // Handle latest products success
    case LATEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        latestProducts: action.payload,
      };

    // Handle failures for all product actions
    case ALL_PRODUCT_FAIL:
    case ADMIN_PRODUCT_FAIL:
    case SHUFFLED_PRODUCTS_FAIL:
    case LATEST_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    // Clear error
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    // Default case
    default:
      return state;
  }
};

//cretae a product reducer

export const newDummyProductReducer = (state = { newProductData: [] }, action) => {
  switch (action.type) {
    case NEW_DUMMY_PRODUCT_REQUEST: {
      return { loading: true };
    }

    case NEW_DUMMY_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        newProductData: action.payload.data,
      };

    case NEW_DUMMY_PRODUCT_FAIL: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    case NEW_DUMMY_PRODUCT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

// delte and Upadate reducer :

export function deleteUpdateDummyReducer(state = { product: {} }, action) {
  switch (action.type) {
    case DELETE_DUMMY_PRODUCT_REQUEST:
    case UPDATE_DUMMY_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_DUMMY_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_DUMMY_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_DUMMY_PRODUCT_FAIL:
    case UPDATE_DUMMY_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DUMMY_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case DELETE_DUMMY_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export const countriesReducer = (state = { countries: [] }, action) => {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case COUNTRIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export const updateCountriesReducer = (state = { countries: {}}, action) => {
  switch (action.type) {
    case UPDATE_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case UPDATE_COUNTRIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_COUNTRIES_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}